import {io, Socket} from "socket.io-client";
import {BehaviorSubject} from 'rxjs';
import IWDevice from "./IWDevice";

enum ConnectionState {
    Disconnected = "DISCONNECTED",
    Connecting = "CONNECTING",
    Connected = "CONNECTED",
    Reconnecting = "RECONNECTING",
}

class IWBrokerClient {
    requestId: number = 0;
    brokerURL: string;
    socket: Socket;
    connectionState$: BehaviorSubject<ConnectionState>;
    alarmDisplayLine1$: BehaviorSubject<string>;
    alarmDisplayLine2$: BehaviorSubject<string>;
    alarmState$: BehaviorSubject<string>;
    workshopDoorRollerState$: BehaviorSubject<string>;
    user$: BehaviorSubject<any>;

    constructor() {
        this.brokerURL = `${window.location.protocol}//${window.location.hostname}:${window.location.protocol === 'http:' ? 8501 : window.location.port}`;

        this.connectionState$ = new BehaviorSubject<ConnectionState>(ConnectionState.Connecting);
        this.user$ = new BehaviorSubject<any>(undefined);
        this.alarmDisplayLine1$ = new BehaviorSubject<string>('');
        this.alarmDisplayLine2$ = new BehaviorSubject<string>('');
        this.alarmState$ = new BehaviorSubject<string>('');
        this.workshopDoorRollerState$ = new BehaviorSubject<string>('');

        this.socket = io(this.brokerURL, {
            transports: ['websocket'],
            query: {
                platform: IWDevice.getPlatforms(),
                device: "ionic-app"
            },
            auth: (async (cb) => {
                cb({
                    token: await IWDevice.getDeviceToken()
                });
            })
        });

        this.socket.on('connect', () => {
            console.info(`(${this.constructor.name}) Connected.`);
            this.connectionState$.next(ConnectionState.Connected);
        });

        this.socket.on('connect_error', (error: any) => {
            console.warn(`(${this.constructor.name}) Connect Error`, error);
        });

        this.socket.on('disconnect', () => {
            console.warn(`(${this.constructor.name}) Disconnected.`);
            this.connectionState$.next(ConnectionState.Disconnected);
        });

        this.socket.io.on('reconnect_attempt', () => {
            console.info(`(${this.constructor.name}) Reconnect attempt`);
            this.connectionState$.next(ConnectionState.Reconnecting)
        });

        this.socket.on("user", (user: any) => {
            console.info("(IWBrokerClient) User", user);
            this.user$.next(user);
        });

        this.socket.on("00/alarm/display/line1", (line: string) => this.alarmDisplayLine1$.next(line));
        this.socket.on("00/alarm/display/line2", (line: string) => this.alarmDisplayLine2$.next(line));
        this.socket.on("00/alarm/state", (line: string) => this.alarmState$.next(line));
        this.socket.on("00/workshop/door/roller", (state: string) => this.workshopDoorRollerState$.next(state));
    }

    async socketRequest(socket: any, event: string, ...args: any[]) {
        const requestId = ++this.requestId;

        return new Promise((resolve, reject) => {
            console.log(`[${requestId}] -> `, event, args);

            const disconnectHandler = () => {
                console.log(`[${requestId}] <-x- DISCONNECTED`);

                reject({
                    code: 1006,
                    message: 'Disconnected'
                });
            };

            const timeoutHandler = () => {
                console.warn(`[${requestId}] <-?- Request Timeout?`);
            };

            this.socket.once('disconnect', disconnectHandler);
            const timeout = setTimeout(timeoutHandler, 30000);

            this.socket.emit(event, ...args, (response: any) => {
                this.socket.off('disconnect', disconnectHandler);
                clearInterval(timeout);

                console.log(`[${requestId}] <- `, event, response);

                if (response?.code && response.code !== 200) {
                    console.error(event, response);
                    reject(response);
                }

                resolve(response);
            });
        });
    }

    async volatileRequest(event: string, ...args: any[]) {
        return this.socketRequest(this.socket.volatile, event, ...args);
    }

    async request(event: string, ...args: any[]) {
        return this.socketRequest(this.socket, event, ...args);
    }

    async sendAlarmKey(key: string) {
        return this.request("00/alarm/key", key);
    }

    async requestAlarmSet() {
        return this.request("00/alarm/set");
    }

    async requestAlarmUnset() {
        return this.request("00/alarm/unset");
    }

    async httpRequest(path: string, data: object): Promise<Response> {
        return await fetch(this.brokerURL + path, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
    }

    getCameraMjpegURL(cameraIp: string) {
        return this.brokerURL + '/camera/' + cameraIp + '/mjpeg';
    }

    getAvatarURL(userId: Number) {
        return this.brokerURL + '/avatar/' + userId;
    }

    getHVACGraphURL(groupId: Number, period: String = '1d', nonce: any) {
        return this.brokerURL + '/hvac/graph/' + groupId + '?time=' + period + '&nonce=' + nonce;
    }
}

export default new IWBrokerClient();
import {
    IonBackButton, IonButton,
    IonButtons,
    IonContent,
    IonGrid,
    IonHeader,
    IonPage,
    IonRow,
    IonTitle,
    IonToolbar, IonItem,
    IonCol, IonLabel, IonChip, IonIcon, IonProgressBar
} from '@ionic/react';
import {isPlatform} from '@ionic/react';
import React, {memo, useState, useEffect} from "react";
import styles from './AccessAlarm.module.css';
import IWBrokerClient from "../client/IWBrokerClient";
import {
    shieldCheckmark,
    shieldHalf,
    lockOpenOutline,
    warning
} from "ionicons/icons";
import useUX from "../ux/UX";

const AccessAlarm: React.FC = () => {
    const [alarmState, setAlarmState] = useState('');
    const [line1, setLine1] = useState('-');
    const [line2, setLine2] = useState('-');
    const [requestingAlarmState, setRequestingAlarmState] = useState(false);
    const {isConnecting, presentResult} = useUX();
    const displayBackgroundText = '\u0953'.repeat(16);

    useEffect(() => {
        const subscriptions: any[] = [
            IWBrokerClient.alarmState$.subscribe(state => setAlarmState(state)),
            IWBrokerClient.alarmDisplayLine1$.subscribe(value => setLine1(value)),
            IWBrokerClient.alarmDisplayLine2$.subscribe(value => setLine2(value))
        ];

        return () => subscriptions.forEach(s => s.unsubscribe());
    }, []);

    async function setAlarm(set: boolean) {
        setRequestingAlarmState(true);

        await presentResult(async () => {
            if (set) {
                await IWBrokerClient.requestAlarmSet();
            } else {
                await IWBrokerClient.requestAlarmUnset();
            }
        }, `${set ? 'Set' : 'Unset'} Command Issued`);

        setRequestingAlarmState(false);
    }

    async function sendKey(key: string) {
        await IWBrokerClient.sendAlarmKey(key);
    }

    return (
        <IonPage>
            <IonHeader translucent={isPlatform('capacitor')}>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="../"/>
                    </IonButtons>
                    <IonTitle>Alarm Control</IonTitle>
                    {!!(isConnecting || requestingAlarmState) && <IonProgressBar type="indeterminate"/>}
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen={true}>
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large">Alarm Control</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <div className={styles.alarmDisplay}>
                    <div className={styles.alarmDisplayBackground}>
                        <div>{displayBackgroundText}</div>
                        <div>{displayBackgroundText}</div>
                    </div>
                    <div className={styles.alarmDisplayContent}>
                        <div>{line1}</div>
                        <div>{line2}</div>
                    </div>
                </div>
                <IonItem lines="full">
                    <IonChip slot="start" color={{
                        ARMED: 'danger',
                        TRIGGERED: 'danger',
                        DISARMED: 'success'
                    }[alarmState] || 'warning'}>
                        <IonIcon
                            icon={{
                                ARMED: shieldCheckmark,
                                DISARMED: lockOpenOutline,
                                TRIGGERED: warning
                            }[alarmState] || shieldHalf}/>
                        <IonLabel className={styles.alarmStateLabel}>{{
                            ARMED: 'Armed',
                            DISARMED: 'Disarmed',
                            TRIGGERED: 'TRIGGERED'
                        }[alarmState] || alarmState}</IonLabel>
                    </IonChip>
                    <IonButton slot="end" disabled={!line1.includes('Galaxy 48') || requestingAlarmState}
                               onClick={() => setAlarm(alarmState === 'DISARMED')} className={styles.button}>
                        <IonIcon slot="start"
                                 icon={alarmState === 'DISARMED' ? shieldCheckmark : lockOpenOutline}/>
                        {alarmState === 'DISARMED' ? 'Set Alarm' : 'Unset Alarm'}
                    </IonButton>
                </IonItem>
                <IonGrid className={styles.keypad}>
                    <IonRow>
                        <IonCol><IonButton expand="block" onClick={() => sendKey('1')}>1</IonButton></IonCol>
                        <IonCol><IonButton expand="block" onClick={() => sendKey('2')}>2</IonButton></IonCol>
                        <IonCol><IonButton expand="block" onClick={() => sendKey('3')}>3</IonButton></IonCol>
                        <IonCol><IonButton expand="block" onClick={() => sendKey('A')}>A ▶</IonButton></IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol><IonButton expand="block" onClick={() => sendKey('4')}>4</IonButton></IonCol>
                        <IonCol><IonButton expand="block" onClick={() => sendKey('5')}>5</IonButton></IonCol>
                        <IonCol><IonButton expand="block" onClick={() => sendKey('6')}>6</IonButton></IonCol>
                        <IonCol><IonButton expand="block" onClick={() => sendKey('B')}>B ◀</IonButton></IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol><IonButton expand="block" onClick={() => sendKey('7')}>7</IonButton></IonCol>
                        <IonCol><IonButton expand="block" onClick={() => sendKey('8')}>8</IonButton></IonCol>
                        <IonCol><IonButton expand="block" onClick={() => sendKey('9')}>9</IonButton></IonCol>
                        <IonCol><IonButton color="success" expand="block"
                                           onClick={() => sendKey('E')}>ent</IonButton></IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol><IonButton expand="block" onClick={() => sendKey('*')}>*</IonButton></IonCol>
                        <IonCol><IonButton expand="block" onClick={() => sendKey('0')}>0</IonButton></IonCol>
                        <IonCol><IonButton expand="block" onClick={() => sendKey('#')}>#</IonButton></IonCol>
                        <IonCol><IonButton color="danger" expand="block"
                                           onClick={() => sendKey('X')}>esc</IonButton></IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default memo(AccessAlarm);

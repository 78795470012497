import IWUser from "./IWUser";

class IWUsers {
    users = new Map<number, any>();

    getUser(userId: number): IWUser {
        if(this.users.has(userId)) {
            return this.users.get(userId);
        } else {
            const user = new IWUser(userId);
            this.users.set(userId, user);
            return user;
        }
    }
}

export default new IWUsers();
import IWBrokerClient from "./IWBrokerClient";
import {BehaviorSubject} from 'rxjs';

class IWBlinds {
    blinds$: BehaviorSubject<Record<string, any>> = new BehaviorSubject({});
    loadingPromise: Promise<any> | null = null;
    loaded: boolean = false;

    constructor() {
        IWBrokerClient.socket.on('user', async () => {
            if(this.blinds$.observed) {
                //Re-request blinds on re-authentication
                await this.request();
            }
            else {
                this.loaded = false;
            }
        });

        IWBrokerClient.socket.on('blind update', (blind: any) => {
            console.log('(IWBlinds) blind update', blind);

            this.blinds$.next({
                ...this.blinds$.getValue(),
                [blind.mac]: blind
            });
        });
    }

    /** Requests charger data (if not cached) */
    requestIfNeeded(): Promise<any> {
        if (this.loaded) {
            return Promise.resolve(this.blinds$.getValue());
        } else if (this.loadingPromise) {
            return this.loadingPromise;
        } else {
            return this.request();
        }
    }

    async request(): Promise<any> {
        this.loadingPromise = IWBrokerClient.request('blind list')
            .then(blinds => {
                this.blinds$.next(blinds as Record<string, any>);

                return blinds;
            })
            .finally(() => {
                this.loadingPromise = null;
            });

        return this.loadingPromise;
    }

    async requestBlindPosition(mac: string, position: number) {
        await IWBrokerClient.request('blind position', {mac, position});

        this.blinds$.next(Object.fromEntries(Object.entries(this.blinds$.getValue()).map(([blindMac, blind]) => {
            return [blindMac, blindMac !== mac ? blind : {
                ...blind,
                data: {
                    ...blind.data,
                    currentPosition: position,
                }
            }];
        })));
    }

    async requestAllBlindsPosition(position: number) {
        await IWBrokerClient.request('blind all position', {position});

        this.blinds$.next(Object.fromEntries(Object.entries(this.blinds$.getValue()).map(([mac, blind]) => {
            return [mac, {
                ...blind,
                data: {
                    ...blind.data,
                    currentPosition: position,
                }
            }];
        })));
    }
}

export default new IWBlinds();
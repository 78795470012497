import {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
    IonList,
    IonItemDivider,
    IonItem,
    IonCard,
    IonLabel,
    IonButton, useIonAlert, IonProgressBar
} from '@ionic/react';
import React, {memo, useEffect, useState} from "react";
import "./AccessGuide.css";
import IWDevice from "../client/IWDevice";
import IWBrokerClient from "../client/IWBrokerClient";
import {PushNotifications} from '@capacitor/push-notifications';
import {isPlatform} from '@ionic/react';
import IWPushNotifications from "../client/IWPushNotifications";
import IWApp from "../plugins/IWAppPlugin";
import useUX from "../ux/UX";

const Settings: React.FC = () => {
    const {isConnecting} = useUX();
    const [user, setUser] = useState<any>({});
    const [deviceToken, setDeviceToken] = useState<string | undefined>();
    const [pushEnvironment, setPushEnvironment] = useState<string | undefined>();
    const [platform, setPlatform] = useState<string | undefined>();
    const [presentAlert] = useIonAlert();

    async function revealAlarmCode() {
        return presentAlert({
            header: 'Alarm Code',
            subHeader: 'Your personal alarm code:',
            message: `Code: <strong>${user.alarmCode.code}</strong>`,
            buttons: ['OK'],
        });
    }

    async function registerPushNotifications() {
        // Request permission to use push notifications
        // iOS will prompt user and return if they granted permission or not
        // Android will just grant without prompting
        let result;

        try {
            result = await IWApp.requestCriticalAlertPermissions();
        } catch (e) {
            console.warn("<Settings.registerPushNotifications> IWApp.requestCriticalAlertPermissions not successful, falling back to PushNotifications.requestPermissions", e);
            result = await PushNotifications.requestPermissions();
        }

        if (result.receive === 'granted') {
            // Register with Apple / Google to receive push via APNS/FCM
            await PushNotifications.register();
        } else {
            alert('Error requesting permission: ' + result.receive);
        }
    }

    useEffect(() => {
        IWPushNotifications.getPushEnvironment().then(environment => {
            setPushEnvironment(environment);
        });

        const subscriptions = [
            IWBrokerClient.user$.subscribe(value => setUser(value))
        ];

        (async () => {
            setDeviceToken(await IWDevice.getDeviceToken());
        })();

        setPlatform(IWDevice.getPlatforms().join(','));

        return () => subscriptions.forEach(s => s.unsubscribe());
    }, []);

    return (
        <IonPage>
            <IonHeader translucent={isPlatform('capacitor')}>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="../"/>
                    </IonButtons>
                    <IonTitle>Settings</IonTitle>
                    {isConnecting && <IonProgressBar type="indeterminate"/>}
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen={true}>
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large">Settings</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonCard color={user?.id ? "success" : "danger"}>
                    <IonCardHeader>
                        <IonCardTitle>{user?.id ? `Welcome, ${user.name}` : 'Device not Registered'}</IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>
                        {user?.id ? 'You are signed in.' : 'Contact Martin to register this device.'}
                    </IonCardContent>
                </IonCard>

                <IonList>
                    <IonItemDivider>User Information</IonItemDivider>
                    <IonItem>
                        <IonLabel>Device Token</IonLabel>
                        {deviceToken}
                    </IonItem>
                    <IonItem>
                        <IonLabel>User ID</IonLabel>
                        {user?.id}
                    </IonItem>
                    <IonItem>
                        <IonLabel>Email</IonLabel>
                        {user?.email}
                    </IonItem>
                    <IonItem>
                        <IonLabel>Name</IonLabel>
                        {user?.name}
                    </IonItem>

                    <IonItemDivider>Alarm Access</IonItemDivider>
                    <IonItem>
                        <IonLabel>Status</IonLabel>
                        {user?.alarmCode?.code ? 'Configured' : 'Not Configured'}
                    </IonItem>
                    <IonItem>
                        <IonLabel>Alarm Code ID</IonLabel>
                        {user?.alarmCode?.id || '-'}
                    </IonItem>
                    <IonItem>
                        <IonLabel>Alarm Code</IonLabel>
                        {user?.alarmCode?.code
                            ? <IonButton onClick={revealAlarmCode}>Reveal Code</IonButton>
                            : '-'}
                    </IonItem>

                    <IonItemDivider>Device</IonItemDivider>
                    <IonItem>
                        <IonLabel>Platform</IonLabel>
                        {platform}
                    </IonItem>

                    <IonItemDivider>Push Notifications</IonItemDivider>
                    <IonItem>
                        <IonLabel>Push Environment</IonLabel>
                        {pushEnvironment}
                    </IonItem>
                    <IonItem>
                        <IonLabel>Push Notifications</IonLabel>
                        <IonButton onClick={registerPushNotifications}
                                   disabled={!isPlatform('capacitor')}>Register</IonButton>
                    </IonItem>
                </IonList>
            </IonContent>
        </IonPage>
    );
};

export default memo(Settings);

import { getPlatforms } from '@ionic/react';
import { Drivers, Storage } from '@ionic/storage';
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';

class IWDevice {
    deviceToken?: string;

    generateNewToken() {
        let d = new Date().getTime();

        let uuid = 'WExxxxxxxx'.replace(/[xy]/g, c => {
            let r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return r.toString(16);
        });

        return uuid;
    }

    async getDeviceToken() {
        //1. Have we already read and cached a token?
        if(this.deviceToken) {
            console.info("(IWDevice.getDeviceToken) Device Token (Cached)", this.deviceToken);
            return this.deviceToken;
        }

        //2. Do we have a token in Ionic storage?
        const store = new Storage({
            driverOrder: [CordovaSQLiteDriver._driver, Drivers.IndexedDB, Drivers.LocalStorage]
        });
        await store.defineDriver(CordovaSQLiteDriver);
        await store.create();

        this.deviceToken = await store.get('deviceToken');

        if(this.deviceToken) {
            console.info("(IWDevice.getDeviceToken) Device Token (Ionic Storage)", this.deviceToken);
            return this.deviceToken;
        }

        //3. Do we have a token in the local storage?
        if(localStorage.deviceToken) {
            this.deviceToken = localStorage.deviceToken;
            console.info("(IWDevice.getDeviceToken) Device Token (Local Storage)", this.deviceToken);
            await store.set('deviceToken', this.deviceToken);
            return this.deviceToken;
        }

        //4. Generate a new token
        this.deviceToken = this.generateNewToken();
        console.info("(IWDevice.getDeviceToken) Device Token (Newly Generated)", this.deviceToken);
        await store.set('deviceToken', this.deviceToken);
        return this.deviceToken;
    }

    getPlatforms() {
        return getPlatforms();
    }
}

export default new IWDevice();
import {BehaviorSubject} from "rxjs";
import IWBrokerClient from "./IWBrokerClient";

class IWUser extends BehaviorSubject<any> {
    loadingPromise: Promise<any> | null = null;
    loaded: boolean = false;

    constructor(userId: number) {
        console.log('(IWUser.constructor)', userId);

        super({id: userId});
    }

    async request() {
        const userId = this.getValue().id;

        console.log('(IWUser.requestUser)', userId);

        this.loadingPromise = IWBrokerClient.request('user', {userId})
            .then(userInfo => {
                this.next(userInfo);

                return userInfo;
            })
            .finally(() => {
                this.loadingPromise = null;
            });

        return this.loadingPromise;
    }

    requestIfNeeded(): Promise<any> {
        if (this.loaded) {
            // We already have cached user data. Resolve the request.
            return Promise.resolve(this.getValue());
        } else if (this.loadingPromise) {
            // We're already loading user data. Return existing promise.
            return this.loadingPromise;
        } else {
            // Request user data from the server. Return the promise.
            return this.request();
        }
    }
}

export default IWUser;
import IWBrokerClient from "./IWBrokerClient";
import {BehaviorSubject} from 'rxjs';

class IWHVAC {
    groups$: BehaviorSubject<Record<number, any>> = new BehaviorSubject({});
    groupsLoadingPromise: any;
    groupsLoaded: boolean = false;

    constructor() {
        IWBrokerClient.socket.on('user', async () => {
            if(this.groups$.observed) {
                //Re-request groups on re-authentication
                await this.requestGroups();
            }
            else {
                this.groupsLoaded = false;
            }
        });

        IWBrokerClient.socket.on('hvac group update', (group: any) => {
            console.log('(IWHVAC) hvac group update', group);

            this.groups$.next({
                ...this.groups$.getValue(),
                [group.id]: group
            });
        });
    }

    /** Requests groups data (if not cached */
    requestGroupsIfNeeded(): Promise<any> {
        if (this.groupsLoaded) {
            return Promise.resolve(this.groups$.getValue());
        } else if (this.groupsLoadingPromise) {
            return this.groupsLoadingPromise;
        } else {
            return this.requestGroups();
        }
    }

    async requestGroups(): Promise<any> {
        this.groupsLoadingPromise = IWBrokerClient.request('hvac group list')
            .then(groups => {
                this.groups$.next(groups as Record<number, any>);

                return groups;
            })
            .finally(() => {
                this.groupsLoadingPromise = null;
            });

        return this.groupsLoadingPromise;
    }

    async sendSetting(groupId: number, setting: string, value: any) {
        return await IWBrokerClient.request('hvac group setting', {
            groupId,
            setting,
            value
        });
    }
}

export default new IWHVAC();
import React, {useEffect, useState} from 'react';
import {
    IonChip,
    IonCol,
    IonContent, IonGrid,
    IonHeader,
    IonIcon,
    IonItemDivider, IonNote,
    IonPage,
    IonRow,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonImg, IonProgressBar
} from '@ionic/react';
import {IonButton, IonList, IonItem, IonLabel} from '@ionic/react';
import {useIonAlert} from '@ionic/react';
import {useIonRouter} from "@ionic/react";
import {isPlatform} from '@ionic/react';
import styles from './Access.module.css';
import {
    enterOutline,
    exitOutline,
    shieldCheckmark,
    lockOpen,
    personCircleOutline,
    warning
} from "ionicons/icons";
import useUX from "../ux/UX";
import IWBrokerClient from "../client/IWBrokerClient";
import SoftwareUpdateIndicatorButton from "../components/SoftwareUpdateIndicatorButton";

function AlarmStateChip({state}: any) {
    switch (state) {
        case 'DISARMED':
            return (
                <IonLabel slot="end" color="medium">Disarmed</IonLabel>
            );

        case 'ARMED':
            return (
                <IonChip slot="end" color="danger">
                    <IonIcon icon={shieldCheckmark}/>
                    <IonLabel>Armed</IonLabel>
                </IonChip>
            );

        case 'TRIGGERED':
            return (
                <IonChip slot="end" color="danger">
                    <IonIcon icon={warning}/>
                    <IonLabel>TRIGGERED</IonLabel>
                </IonChip>
            );

        default:
            return state;
    }
}

function DoorStateChip({state}: any) {
    switch (state) {
        case 'CLOSED':
            return (
                <IonLabel slot="end" color="medium">Closed</IonLabel>
            );

        case 'OPEN':
            return (
                <IonChip slot="end" color="warning">
                    <IonIcon icon={lockOpen}/>
                    <IonLabel>Open</IonLabel>
                </IonChip>
            );

        default:
            return state;
    }
}

const Access: React.FC = () => {
    const [workshopDoorRollerState, setWorkshopDoorRollerState] = useState('');
    const [user, setUser] = useState<any>({});
    const [presentAlert] = useIonAlert();
    const {
        revealExteriorGatePin,
        receptionDoorSlideOpen,
        requestingDoorSlide,
        receptionDoorUnlock,
        requestingDoorUnlock,
        isConnecting,
        alarmState
    } = useUX();
    const router = useIonRouter();

    useEffect(() => {
        const subscriptions = [
            IWBrokerClient.user$.subscribe(value => setUser(value)),
            IWBrokerClient.workshopDoorRollerState$.subscribe(state => setWorkshopDoorRollerState(state))
        ];

        return () => subscriptions.forEach(subscription => subscription.unsubscribe());
    }, []);

    async function openWorkshopRollingDoorControl() {
        await presentAlert({
            header: 'Warning',
            message: '<p>App control of the roller door is provided for authorised and emergency use only.</p>' +
                '<p>Always maintain visual contact with the roller door during operation.</p>',
            inputs: [{
                type: 'checkbox',
                name: 'confirm',
                label: 'Confirm & Agree',
                value: 'confirm',
            }],
            buttons: [
                'Cancel',
                {
                    text: 'Proceed',
                    handler: (data) => {
                        if (!data.includes('confirm')) {
                            return false;
                        }

                        router.push('/access/workshop/roller-door');
                    }
                }
            ]
        });
    }

    return (
        <IonPage>
            <IonHeader translucent={isPlatform('capacitor')} collapse="fade">
                <IonToolbar>
                    <IonTitle>Innovation Way</IonTitle>
                    <IonButtons slot="primary">
                        <SoftwareUpdateIndicatorButton/>
                        <IonButton routerLink='/settings'>
                            {user?.id
                                ? (<IonImg className={styles.avatar} src={IWBrokerClient.getAvatarURL(user?.id)}
                                           alt="User Avatar"/>)
                                : (<IonIcon slot="icon-only" icon={personCircleOutline}/>)
                            }
                        </IonButton>
                    </IonButtons>
                    { isConnecting && <IonProgressBar type="indeterminate" /> }
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen={true}>
                <div style={{textAlign: 'center'}}>
                    <img className={styles.banner} src="/assets/banners/winter.jpg" alt="Seasonal Banner"/>
                </div>
                <IonList>
                    <IonItemDivider>Guides</IonItemDivider>
                    <IonGrid className={styles.guides}>
                        <IonRow style={{touchAction: 'none', width: '100%', height: '75px'}}>
                            <IonCol>
                                <IonButton expand="block" routerLink="/access/guide/opening">
                                    <IonIcon slot="start" icon={enterOutline}/>
                                    Opening
                                </IonButton>
                            </IonCol>
                            <IonCol>
                                <IonButton expand="block" routerLink="/access/guide/closing">
                                    <IonIcon slot="start" icon={exitOutline}/>
                                    Closing
                                </IonButton>
                            </IonCol>
                        </IonRow>
                        <IonRow style={{display: 'flex'}}>
                            <IonNote style={{margin: 'auto'}}>Follow these if first to arrive or last to
                                leave.</IonNote>
                        </IonRow>
                    </IonGrid>
                    <IonItemDivider>Alarm</IonItemDivider>
                    <IonItem button detail={true} lines="none" routerLink="/access/alarm">
                        <IonLabel>Alarm</IonLabel>
                        <AlarmStateChip state={alarmState} />
                    </IonItem>
                    <IonItemDivider>Exterior</IonItemDivider>
                    <IonItem>
                        <IonLabel>Exterior Gates</IonLabel>
                        <IonButton onClick={revealExteriorGatePin}>Reveal Pin</IonButton>
                    </IonItem>
                    <IonItemDivider>Reception</IonItemDivider>
                    <IonItem>
                        <IonLabel>Sliding Door</IonLabel>
                        <IonButton disabled={requestingDoorUnlock} onClick={receptionDoorUnlock}>Unlock</IonButton>
                        <IonButton disabled={requestingDoorSlide} onClick={receptionDoorSlideOpen}>Slide
                            Open</IonButton>
                    </IonItem>
                    <IonItemDivider>Workshop</IonItemDivider>
                    <IonItem button detail={true} onClick={openWorkshopRollingDoorControl}>
                        <IonLabel>Roller Door</IonLabel>
                        <DoorStateChip state={workshopDoorRollerState} />
                    </IonItem>
                </IonList>
            </IonContent>
        </IonPage>
    );
};

export default Access;

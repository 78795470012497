import IWBrokerClient from "./IWBrokerClient";
import {BehaviorSubject} from 'rxjs';

class IWLog {
    log$: BehaviorSubject<any[]>;
    logLoadingPromise: any;
    logLoaded: Boolean;

    constructor() {
        this.log$ = new BehaviorSubject([] as any[]);
        this.logLoaded = false;
        this.logLoadingPromise = null;

        IWBrokerClient.socket.on('user', async () => {
            if(this.log$.observed) {
                //Re-request log on re-authentication
                await this.requestLog();
            }
            else {
                this.logLoaded = false;
            }
        });

        IWBrokerClient.socket.on('log', (logEntry: any) => {
            console.log('(IWLog) log entry', logEntry);

            this.log$.next([
                logEntry,
                ...this.log$.getValue(),
            ]);
        });
    }

    /** Requests groups data (if not cached */
    requestLogIfNeeded(): Promise<any[]> {
        if (this.logLoaded) {
            // We already have cached profile data. Resolve the request.
            return Promise.resolve(this.log$.getValue());
        } else if (this.logLoadingPromise) {
            // We're already loading log data. Return existing promise.
            return this.logLoadingPromise;
        } else {
            // Request log data from the server. Return the promise.
            return this.requestLog();
        }
    }

    async requestLog(): Promise<any[]> {
        this.logLoadingPromise = IWBrokerClient.request('log', {})
            .then(log => {
                this.log$.next(log as any[]);

                console.log('(IWLog.requestLog) log', log);

                return log;
            })
            .finally(() => {
                this.logLoadingPromise = null;
            });

        return this.logLoadingPromise;
    }

    async requestMore(): Promise<any[]> {
        const log = this.log$.getValue();
        let timestampBefore = log.length && log[log.length - 1].timestamp;

        console.log('(IWLog.requestMore) requesting page before timestamp ', timestampBefore);

        const page = await IWBrokerClient.request('log', {
            timestampBefore
        }) as any[];

        if(page.length) {
            this.log$.next([...this.log$.getValue(), ...page]);
        }

        return page;
    }
}

export default new IWLog();
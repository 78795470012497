import {
    IonBackButton, IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonProgressBar,
    IonTitle,
    IonToolbar,
    IonToggle
} from '@ionic/react';
import {IonList, IonItem} from '@ionic/react';
import {isPlatform} from '@ionic/react';
import React, {useEffect, useState} from "react";
import useUX from "../ux/UX";
import IWRelays from "../client/IWRelays";
import {IonToggleCustomEvent} from "@ionic/core/dist/types/components";
import {ToggleChangeEventDetail} from "@ionic/core";

const ControlRelays: React.FC = () => {
    const [activeRequests, setActiveRequests] = useState(0);
    const [relays, setRelays] = useState<any[]>([]);
    const {isConnecting, presentResult} = useUX();

    useEffect(() => {
        const subscription = IWRelays.relays$.subscribe(relays => {
            setRelays(Object.values(relays));
        });

        setActiveRequests(r => r + 1);

        IWRelays.requestIfNeeded().finally(() => {
            setActiveRequests(r => r - 1);
        });

        return () => {
            subscription.unsubscribe();
        }
    }, []);

    async function setOn(relayId: number, on: boolean, e: IonToggleCustomEvent<ToggleChangeEventDetail>) {
        const relay = IWRelays.relays$.getValue()[relayId];

        if (relay.state === on) {
            return;
        }

        setActiveRequests(r => r + 1);

        await presentResult(async () => {
            try {
                relay.state = on;

                await IWRelays.requestRelayState(relayId, on);
            } catch (e: any) {
                relay.state = !on;
                throw e;
            }
        }, `${relay.name} turned ${on ? 'on' : 'off'}`);

        setActiveRequests(r => r - 1);
    }

    return (
        <IonPage>
            <IonHeader translucent={isPlatform('capacitor')} collapse="fade">
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/control"/>
                    </IonButtons>
                    <IonTitle>All Relays</IonTitle>
                    {!!(isConnecting || activeRequests) && <IonProgressBar type="indeterminate"/>}
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen={true}>
                <IonList>
                    {relays.map((relay: any) => (
                        <IonItem key={relay.id}>
                            <IonToggle checked={relay.state} onIonChange={e => setOn(relay.id, e.detail.checked, e)}>
                                {relay.name || relay.id}
                            </IonToggle>
                        </IonItem>
                    ))}
                </IonList>
            </IonContent>
        </IonPage>
    );
};

export default ControlRelays;
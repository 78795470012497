import IWBrokerClient from "./IWBrokerClient";
import {BehaviorSubject} from 'rxjs';

class IWRelays {
    relays$: BehaviorSubject<Record<string, any>> = new BehaviorSubject({});
    loadingPromise: Promise<any> | null = null;
    loaded: boolean = false;

    constructor() {
        IWBrokerClient.socket.on('user', async () => {
            if(this.relays$.observed) {
                //Re-request blinds on re-authentication
                await this.request();
            }
            else {
                this.loaded = false;
            }
        });

        IWBrokerClient.socket.on('relay update', (relay: any) => {
            console.log('(IWRelays) relay update', relay);

            this.relays$.next({
                ...this.relays$.getValue(),
                [relay.id]: relay
            });
        });
    }

    /** Requests charger data (if not cached) */
    requestIfNeeded(): Promise<any> {
        if (this.loaded) {
            return Promise.resolve(this.relays$.getValue());
        } else if (this.loadingPromise) {
            return this.loadingPromise;
        } else {
            return this.request();
        }
    }

    async request(): Promise<any> {
        this.loadingPromise = IWBrokerClient.request('relay list')
            .then(relays => {
                this.relays$.next(relays as Record<string, any>);

                return relays;
            })
            .finally(() => {
                this.loadingPromise = null;
            });

        return this.loadingPromise;
    }

    async requestRelayState(id: string | number, state: boolean): Promise<any> {
        await IWBrokerClient.request('relay state', {id, state});

        this.relays$.next(Object.fromEntries(Object.entries(this.relays$.getValue()).map(([relayId, relay]) => {
            return [relayId, relayId !== id ? relay : {
                ...relay,
                state
            }];
        })));
    }
}

export default new IWRelays();
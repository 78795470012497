import {useIonAlert, useIonLoading, useIonToast} from "@ionic/react";
import IWBrokerClient from "../client/IWBrokerClient";
import {useEffect, useState} from "react";
import {alertCircle, checkmarkCircle} from "ionicons/icons";
import {Haptics, NotificationType} from "@capacitor/haptics";

function useUX() {
    const [requestingDoorSlide, setRequestingDoorSlide] = useState(false);
    const [requestingDoorUnlock, setRequestingDoorUnlock] = useState(false);
    const [connectionState, setConnectionState] = useState<string | null>(null);
    const [alarmState, setAlarmState] = useState<string | undefined>();
    const [isConnecting, setIsConnecting] = useState(false);
    const [presentToast] = useIonToast();
    const [presentAlert] = useIonAlert();
    const [present] = useIonLoading();

    useEffect(() => {
        const subscriptions = [
            IWBrokerClient.connectionState$.subscribe(state => {
                setConnectionState(state);
                setIsConnecting(state === 'CONNECTING' || state === 'RECONNECTING');
            }),
            IWBrokerClient.alarmState$.subscribe(state => setAlarmState(state))
        ];

        return () => subscriptions.forEach(subscription => subscription.unsubscribe());
    }, []);

    async function revealExteriorGatePin() {
        return presentAlert({
            header: 'Exterior Gates',
            subHeader: 'Padlocks / Keypad',
            message: 'PIN: <strong>4579 (Z)</strong>',
            buttons: ['OK'],
        });
    }

    async function presentResult(fn: Function, successMessage?: string) {
        try {
            await fn();

            Haptics.notification({type: NotificationType.Success}).catch(e => e);
            await presentToast({
                message: successMessage ?? 'Operation Successful',
                duration: 2500,
                color: 'success',
                icon: checkmarkCircle
            });
        } catch (e: any) {
            Haptics.notification({type: NotificationType.Error}).catch(e => e);
            await presentToast({
                message: e.message,
                duration: 2500,
                color: 'danger',
                icon: alertCircle
            });
        }
    }

    async function receptionDoorUnlock() {
        setRequestingDoorUnlock(true);

        try {
            await IWBrokerClient.volatileRequest('00/reception/door/sliding unlock');

            Haptics.notification({type: NotificationType.Success}).catch(e => e);

            await present({
                message: '<strong>Within 10 seconds:</strong><br /><br />Turn the handle slightly to the right then swiftly to the left.',
                duration: 10000,
                backdropDismiss: true,
                spinner: 'dots',
            });
        } catch (e: any) {
            await presentToast({
                message: e.message,
                duration: 2500,
                color: 'danger',
                icon: alertCircle
            });
        } finally {
            setRequestingDoorUnlock(false);
        }
    }

    async function receptionDoorSlideOpen() {
        setRequestingDoorSlide(true);

        await presentResult(async () => {
            await IWBrokerClient.volatileRequest('00/reception/door/sliding', true);
        }, "Opening Sliding Door");

        setRequestingDoorSlide(false);
    }

    return {
        presentResult,
        revealExteriorGatePin,
        receptionDoorSlideOpen,
        requestingDoorSlide,
        receptionDoorUnlock,
        requestingDoorUnlock,
        connectionState,
        alarmState,
        isConnecting
    }
}

export default useUX;
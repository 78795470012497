import { Redirect, Route } from 'react-router-dom';
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  setupIonicReact
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { key, toggle, list } from 'ionicons/icons';
import Access from './pages/Access';
import Control from './pages/Control';
import ControlHVAC from './pages/ControlHVAC';
import HVACGraphs from './pages/HVACGraphs';
import EV from './pages/EV';
import EVCamera from './pages/EVCamera';
import Log from './pages/Log';
import AccessGuideOpening from "./pages/AccessGuideOpening";
import AccessGuideClosing from "./pages/AccessGuideClosing";
import AccessAlarm from './pages/AccessAlarm';
import AccessWorkshopRollerDoor from "./pages/AccessWorkshopRollerDoor";
import Settings from './pages/Settings'
import ControlBlinds from './pages/ControlBlinds'
import ControlRelays from './pages/ControlRelays'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

setupIonicReact({
  innerHTMLTemplatesEnabled: true
});

const App: React.FC = () => (
  <IonApp>
    <IonReactRouter>
      <IonTabs>
        <IonRouterOutlet>
          <Route exact path="/settings">
            <Settings />
          </Route>
          <Route exact path="/access">
            <Access />
          </Route>
          <Route exact path="/access/alarm">
            <AccessAlarm />
          </Route>
          <Route exact path="/access/workshop/roller-door">
            <AccessWorkshopRollerDoor />
          </Route>
          <Route exact path="/access/guide/opening">
            <AccessGuideOpening />
          </Route>
          <Route exact path="/access/guide/closing">
            <AccessGuideClosing />
          </Route>
          <Route exact path="/control">
            <Control />
          </Route>
          <Route exact path="/control/blinds">
            <ControlBlinds />
          </Route>
          <Route exact path="/control/hvac">
            <ControlHVAC />
          </Route>
          <Route exact path="/control/relays">
            <ControlRelays />
          </Route>
          <Route exact path="/control/hvac/graphs">
            <HVACGraphs />
          </Route>
          <Route exact path="/ev">
            <EV />
          </Route>
          <Route exact path="/ev/camera">
            <EVCamera />
          </Route>
          <Route exact path="/log">
            <Log />
          </Route>
          <Route exact path="/">
            <Redirect to="/access" />
          </Route>
        </IonRouterOutlet>
        <IonTabBar slot="bottom">
          <IonTabButton tab="tab1" href="/access">
            <IonIcon icon={key} />
            <IonLabel>Access</IonLabel>
          </IonTabButton>
          <IonTabButton tab="tab2" href="/control">
            <IonIcon icon={toggle} />
            <IonLabel>Control</IonLabel>
          </IonTabButton>
          <IonTabButton tab="tab3" href="/ev">
            <IonIcon src="/assets/icon/ev.svg" />
            <IonLabel>EVs</IonLabel>
          </IonTabButton>
          <IonTabButton tab="tab4" href="/log">
            <IonIcon icon={list} />
            <IonLabel>Log</IonLabel>
          </IonTabButton>
        </IonTabBar>
      </IonTabs>
    </IonReactRouter>
  </IonApp>
);

export default App;

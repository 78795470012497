import React, {useEffect, useState} from "react";
import IWUsers from "../client/IWUsers";

interface Props {
    userId: number;
}

const UserName: React.FC<Props> = ({ userId }) => {
    const [userInfo, setUserInfo] = useState<any>(null);

    useEffect(() => {
        if(userId) {
            const user$ = IWUsers.getUser(userId);
            const subscription = user$.subscribe(userInfo => setUserInfo(userInfo));
            user$.requestIfNeeded().catch(e => console.warn(e));
            return () => {
                subscription.unsubscribe();
            }
        }
    }, [userId]);

    return userInfo && <span>{userInfo.name}</span>;
};

export default UserName;

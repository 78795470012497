import IWBrokerClient from "./IWBrokerClient";
import IWAppPlugin from "../plugins/IWAppPlugin";
import {PushNotifications} from "@capacitor/push-notifications";
import {isPlatform} from "@ionic/react";
import {App} from "@capacitor/app";

class IWPushNotifications {
    constructor() {
        if (isPlatform('capacitor')) {
            App.addListener('appStateChange', async ({ isActive }) => {
                if(isActive) {
                    await PushNotifications.removeAllDeliveredNotifications();
                }
            });

            (async () => {
                await this.addPushListeners();
                await this.createChannels();
            })();
        }
    }

    async getPushEnvironment() {
        if(isPlatform('android')) {
            return 'fcm';
        }

        try {
            const {environment} = await IWAppPlugin.getPushEnvironment();
            return environment;
        }
        catch (e) {
            return 'Not Available';
        }
    }

    async registerPushToken(pushToken: string, environment?: string) {
        if(!environment) {
            environment = await this.getPushEnvironment();
        }

        return IWBrokerClient.request('push token', {
            pushToken,
            environment
        });
    }

    async addPushListeners() {
        await PushNotifications.addListener('registration', async (token) => {
            try {
                await this.registerPushToken(token.value);

                alert('Registered push token: ' + token.value);
            } catch (e) {
                alert('Registration request error: ' + JSON.stringify(e));
            }
        });

        await PushNotifications.addListener('registrationError', err => {
            alert('Registration error: ' + err.error);
        });
    }

    async createChannels() {
        if(isPlatform('android')) {
            try {
                await PushNotifications.createChannel({
                    description: 'Alarm System Triggered / Emergency',
                    id: 'alarm_triggered',
                    importance: 5,
                    lights: true,
                    name: 'Alarm Triggered',
                    sound: 'alarm.wav',
                    vibration: true,
                    visibility: 1,
                    lightColor: '#FF0000'
                });

                console.log('(IWPushNotifications) push channel created: alarm_triggered');
            }
            catch (error) {
                console.error('(IWPushNotifications) push channel error: ', error);
            }
        }
    }
}

export default new IWPushNotifications();
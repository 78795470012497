import {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonCardHeader,
    IonCardTitle,
    IonCardContent, IonIcon, IonLabel, IonChip, useIonRouter
} from '@ionic/react';
import {IonButton, IonCard} from '@ionic/react';
import {isPlatform} from '@ionic/react';
import React, {memo} from "react";
import useUX from "../ux/UX";
import "./AccessGuide.css";
import {lockOpenOutline, shieldCheckmark, shieldHalf, warning} from "ionicons/icons";

const AccessGuideClosing: React.FC = () => {
    const {revealExteriorGatePin, alarmState} = useUX();
    const router = useIonRouter();

    return (
        <IonPage>
            <IonHeader translucent={isPlatform('capacitor')}>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="../"/>
                    </IonButtons>
                    <IonTitle>Closing Guide</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen={true}>
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large">Closing Guide</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonCard color="primary">
                    <IonCardHeader>
                        <IonCardTitle>Closing the Building</IonCardTitle>
                    </IonCardHeader>

                    <IonCardContent>
                        Follow this guide if you're the last to leave the site.
                    </IonCardContent>
                </IonCard>

                <IonCard>
                    <IonCardHeader>
                        <IonCardTitle>1. Disable Automatic Door</IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>
                        Set both sliding door controllers to 🔑 night mode.
                    </IonCardContent>
                </IonCard>

                <IonCard>
                    <IonCardHeader>
                        <IonCardTitle>2. Turn off Reception Lights</IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>
                        Use the light switch near the fire alarm to turn off reception lights.
                        Other building lights will later go off automatically.
                    </IonCardContent>
                </IonCard>

                <IonCard>
                    <IonCardHeader>
                        <IonCardTitle>3. Lock Reception Door</IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>
                        After exiting, the reception door will close automatically. Turn the handle to the right to lock
                        it.
                    </IonCardContent>
                </IonCard>

                <IonCard>
                    <IonCardHeader>
                        <IonCardTitle>4. Set the Alarm</IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>
                        {alarmState && (<IonChip outline={true} onClick={() => router.push('/access/alarm')} color={{
                            ARMED: 'danger',
                            DISARMED: 'success'
                        }[alarmState] || 'warning'}>
                            <IonIcon
                                icon={{
                                    ARMED: shieldCheckmark,
                                    DISARMED: lockOpenOutline,
                                    TRIGGERED: warning
                                }[alarmState] || shieldHalf}/>
                            <IonLabel>
                                {{
                                    ARMED: 'The alarm is set',
                                    DISARMED: 'The alarm is not set'
                                }[alarmState] || alarmState}
                            </IonLabel>
                        </IonChip>)}
                    </IonCardContent>
                    <IonButton fill="clear" routerLink="/access/alarm">Alarm Control</IonButton>
                </IonCard>

                <IonCard>
                    <IonCardHeader>
                        <IonCardTitle>5. Exterior Gates</IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>
                        If you need to close any of the exterior gates, use the PIN code provided for the padlocks.
                    </IonCardContent>
                    <IonButton fill="clear" onClick={revealExteriorGatePin}>Reveal PIN</IonButton>
                </IonCard>
            </IonContent>
        </IonPage>
    );
};

export default memo(AccessGuideClosing);

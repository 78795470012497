import {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonCardHeader,
    IonCardTitle,
    IonCardContent, IonLabel, IonIcon, IonChip, useIonRouter
} from '@ionic/react';
import {IonButton, IonCard} from '@ionic/react';
import {isPlatform} from '@ionic/react';
import React, {memo} from "react";
import useUX from "../ux/UX";
import "./AccessGuide.css";
import {lockOpenOutline, shieldCheckmark, shieldHalf, warning} from "ionicons/icons";

const AccessGuideOpening: React.FC = () => {
    const router = useIonRouter();
    const {
        receptionDoorSlideOpen,
        requestingDoorSlide,
        receptionDoorUnlock,
        requestingDoorUnlock,
        revealExteriorGatePin,
        alarmState
    } = useUX();

    return (
        <IonPage>
            <IonHeader translucent={isPlatform('capacitor')}>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="../"/>
                    </IonButtons>
                    <IonTitle>Opening Guide</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen={true}>
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large">Opening Guide</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonCard color="primary">
                    <IonCardHeader>
                        <IonCardTitle>Opening the Building</IonCardTitle>
                    </IonCardHeader>

                    <IonCardContent>
                        Follow this guide if you're the first to arrive on-site.
                    </IonCardContent>
                </IonCard>

                <IonCard>
                    <IonCardHeader>
                        <IonCardTitle>1. Exterior Gates</IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>
                        If you need to open any of the exterior gates, use the PIN code provided for padlocks and
                        pedestrian gate keypad.
                    </IonCardContent>
                    <IonButton fill="clear" onClick={revealExteriorGatePin}>Reveal PIN</IonButton>
                </IonCard>

                <IonCard>
                    <IonCardHeader>
                        <IonCardTitle>2. Unset the Alarm</IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>
                        {alarmState && (<IonChip outline={true} onClick={() => router.push('/access/alarm')}
                                                  color={{
                                                      ARMED: 'danger',
                                                      DISARMED: 'success'
                                                  }[alarmState] || 'warning'}>
                        <IonIcon
                            icon={{
                                ARMED: shieldCheckmark,
                                DISARMED: lockOpenOutline,
                                TRIGGERED: warning
                            }[alarmState] || shieldHalf}/>
                        <IonLabel>
                            {{
                                ARMED: 'The alarm is set',
                                DISARMED: 'The alarm is not set'
                            }[alarmState] || alarmState}
                        </IonLabel>
                    </IonChip>)}
                    </IonCardContent>
                    <IonButton fill="clear" routerLink="/access/alarm">Alarm Control</IonButton>
                </IonCard>

                <IonCard>
                    <IonCardHeader>
                        <IonCardTitle>3. Unlock Reception Door</IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>
                        <ol>
                            <li>Press unlock below, or scan your fingerprint on the lock.</li>
                            <li>Turn the handle slightly to the right then swiftly to the left.</li>
                        </ol>
                    </IonCardContent>
                    <IonButton fill="clear" onClick={receptionDoorUnlock}
                               disabled={requestingDoorUnlock}>Unlock</IonButton>
                </IonCard>

                <IonCard>
                    <IonCardHeader>
                        <IonCardTitle>4. Open Reception Door</IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>
                        Once the physical lock is opened, slide open the door electronically.
                    </IonCardContent>
                    <IonButton fill="clear" onClick={receptionDoorSlideOpen} disabled={requestingDoorSlide}>Slide
                        Open</IonButton>
                </IonCard>

                <IonCard>
                    <IonCardHeader>
                        <IonCardTitle>5. Enable Automatic Door</IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>
                        Follow instruction label near the reception door to enable automatic operation.
                    </IonCardContent>
                </IonCard>
            </IonContent>
        </IonPage>
    );
};

export default memo(AccessGuideOpening);

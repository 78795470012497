import {registerPlugin} from '@capacitor/core';
import type { PermissionState } from '@capacitor/core';

export interface IWAppPlugin {
    requestCriticalAlertPermissions(): Promise<PermissionStatus>;
    getPushEnvironment(): Promise<PushEnvironment>
}

const IWApp = registerPlugin<IWAppPlugin>('IWApp');

export interface PermissionStatus {
    receive: PermissionState;
}

declare type PushEnvironmentType = 'development' | 'production';

export interface PushEnvironment {
    environment: PushEnvironmentType;
}

export default IWApp;
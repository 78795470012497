import {GroupedVirtuoso} from 'react-virtuoso';
import {
    IonContent,
    IonHeader,
    IonItemDivider,
    IonPage,
    IonProgressBar,
    IonTitle,
    IonToolbar,
    IonInfiniteScroll,
    IonInfiniteScrollContent, isPlatform
} from '@ionic/react';
import {IonAvatar, IonItem, IonLabel, IonNote} from '@ionic/react';
import styles from './Log.module.css';
import React, {memo, useEffect, useState, useCallback} from "react";
import IWBrokerClient from "../client/IWBrokerClient";
import useUX from "../ux/UX";
import IWLog from "../client/IWLog";
import {IonInfiniteScrollCustomEvent} from "@ionic/core/dist/types/components";
import UserName from "../components/UserName";

const Log: React.FC = () => {
    const {isConnecting} = useUX();
    const [loading, setLoading] = useState<boolean>(true);
    const [reachedEnd, setReachedEnd] = useState<boolean>(false);
    const [logRows, setLogRows] = useState<any[]>([]);
    const [logGroupCounts, setLogGroupCounts] = useState<number[]>([]);
    const [logGroups, setLogGroups] = useState<string[]>([]);

    useEffect(() => {
        function collateLogRows(rows: any[]) {
            const logRows: any[] = [];
            const logGroupCounts: number[] = [];
            const logGroups: string[] = [];
            let lastDate: string | null = null;

            const dateFormat = Intl.DateTimeFormat('default', {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric'
            });

            for (const row of rows) {
                const date = new Date(row.timestamp);
                const dateStr = dateFormat.format(date)

                if (dateStr !== lastDate) {
                    lastDate = dateStr;
                    logGroups.push(dateStr);
                    logGroupCounts.push(0);
                }

                logGroupCounts[logGroupCounts.length - 1]++;
                logRows.push(row);
            }

            setLogRows(rows);
            setLogGroupCounts(logGroupCounts);
            setLogGroups(logGroups);
        }

        const subscription = IWLog.log$.subscribe(log => {
            collateLogRows(log);
            setReachedEnd(false);
        });

        setLoading(true);
        IWLog.requestLogIfNeeded().finally(() => setLoading(false));

        return () => {
            subscription.unsubscribe();
        }
    }, []);

    const formatTimestamp = useCallback((timestamp: string) => {
        return Intl.DateTimeFormat('default', {
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric'
        }).format(new Date(timestamp))
    }, []);

    const loadMore = useCallback(async (event: IonInfiniteScrollCustomEvent<void>) => {
        try {
            setLoading(true);
            const page = await IWLog.requestMore();
            if(!page.length) {
                console.log('<Log> Reached final page');
                setReachedEnd(true);
            }
        }
        finally {
            setLoading(false);
            await event.target.complete();
        }
    }, []);

    const Footer = useCallback(() => {
        return (
            <IonInfiniteScroll onIonInfinite={loadMore} disabled={reachedEnd} threshold="100px">
                <IonInfiniteScrollContent />
            </IonInfiniteScroll>
        )
    }, [loadMore, reachedEnd]);

    function escapeHTML(unsafeText: string) {
        /* Escapes HTML, but preserves \n, unlike React */
        let div = document.createElement('div');
        div.innerText = unsafeText;
        return div.innerHTML;
    }

    return (
        <IonPage>
            <IonHeader translucent={isPlatform('capacitor')} collapse="fade">
                <IonToolbar>
                    <IonTitle>Event Log</IonTitle>
                    {(loading || isConnecting) && <IonProgressBar type="indeterminate"/>}
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen={true} scrollY={false}>
                <GroupedVirtuoso
                    className="ion-content-scroll-host"
                    groupCounts={logGroupCounts}
                    itemContent={(index: number, groupIndex: number) => {
                        const row = logRows[index];

                        return (
                            <div>
                                <IonItem className={styles.listItem}>
                                    <IonAvatar slot="start">
                                        <img alt="avatar" src={IWBrokerClient.getAvatarURL(row.userId)}/>
                                    </IonAvatar>
                                    <IonLabel class="ion-text-wrap">
                                        <IonNote
                                            className={styles.itemTimestamp}>{formatTimestamp(row.timestamp)}</IonNote>
                                        <h2 className={styles.itemTitle}>{
                                            row.name
                                            || (row.userId && <UserName userId={row.userId} />)
                                            || row.token
                                            || 'Innovation Way'}</h2>
                                        <p dangerouslySetInnerHTML={{__html: escapeHTML(row.message)}} />
                                    </IonLabel>
                                </IonItem>
                            </div>
                        );
                    }}
                    groupContent={(index: number) => {
                        return (
                            <IonItemDivider>{logGroups[index]}</IonItemDivider>
                        );
                    }}
                    components={{ Footer }}
                />
            </IonContent>
        </IonPage>
    );
};

export default memo(Log);

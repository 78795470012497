import {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonProgressBar
} from '@ionic/react';
import {IonCard} from '@ionic/react';
import {useIonViewWillEnter, useIonViewDidLeave} from '@ionic/react';
import {isPlatform} from '@ionic/react';
import IWBrokerClient from "../client/IWBrokerClient";
import React, {memo, useState} from "react";
import useUX from "../ux/UX";

const EVCamera: React.FC = () => {
    const {isConnecting} = useUX();

    const [cameraMjpegSrc, setCameraMjpegSrc] = useState<string | null>(null);

    useIonViewWillEnter(() => {
        console.log('Starting Webcam Stream');

        setCameraMjpegSrc(IWBrokerClient.getCameraMjpegURL('10.0.4.118'));
    });

    useIonViewDidLeave(() => {
        console.log('Stopping Webcam Stream')

        setCameraMjpegSrc(null);
        window.stop();
    });

    return (
        <IonPage>
            <IonHeader translucent={isPlatform('capacitor')}>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/ev"/>
                    </IonButtons>
                    <IonTitle>EV Park View</IonTitle>
                    {!!isConnecting && <IonProgressBar type="indeterminate"/>}
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen={true}>
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large">EV Park View</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonCard>
                    <div id="camera-container">
                        { cameraMjpegSrc && <img alt="Camera View" id="camera" src={cameraMjpegSrc}/> }
                    </div>
                </IonCard>
            </IonContent>
        </IonPage>
    );
};

export default memo(EVCamera);
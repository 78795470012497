import IWBrokerClient from "./IWBrokerClient";
import {BehaviorSubject} from 'rxjs';

class IWEV {
    chargers$: BehaviorSubject<any> = new BehaviorSubject({});
    chargersLoadingPromise: Promise<any> | null = null;
    chargersLoaded: boolean = false;

    constructor() {
        IWBrokerClient.socket.on('user', async () => {
            if(this.chargers$.observed) {
                //Re-request chargers on re-authentication
                await this.requestChargers();
            }
            else {
                this.chargersLoaded = false;
            }
        });

        IWBrokerClient.socket.on('ev charger update', (charger: any) => {
            console.log('(IWEV) ev charger update', charger);

            this.chargers$.next({
                ...this.chargers$.getValue(),
                [charger.id]: charger
            });
        });
    }

    /** Requests charger data (if not cached) */
    requestChargersIfNeeded(): Promise<any> {
        if (this.chargersLoaded) {
            return Promise.resolve(this.chargers$.getValue());
        } else if (this.chargersLoadingPromise) {
            return this.chargersLoadingPromise;
        } else {
            return this.requestChargers();
        }
    }

    async requestChargers(): Promise<any> {
        this.chargersLoadingPromise = IWBrokerClient.request('ev charger list')
            .then(chargers => {
                this.chargers$.next(chargers);

                return chargers;
            })
            .finally(() => {
                this.chargersLoadingPromise = null;
            });

        return this.chargersLoadingPromise;
    }

    async sendRequest(chargePointId: string, request: any) {
        console.log('(IWEV) sendRequest', chargePointId, request);

        return IWBrokerClient.request('ev charger request', {
            chargePointId: chargePointId,
            ...request
        });
    }
}

export default new IWEV();
import {
    IonBackButton, IonButtons, IonLabel,
    IonContent,
    IonHeader,
    IonPage,
    IonProgressBar,
    IonTitle,
    IonToolbar,
    IonIcon, IonButton
} from '@ionic/react';
import {IonList, IonItem} from '@ionic/react';
import {isPlatform} from '@ionic/react';
import React, {useEffect, useState} from "react";
import useUX from "../ux/UX";
import IWBlinds from "../client/IWBlinds";
import {warningOutline} from "ionicons/icons";
import BlindControlSlider from "../components/BlindControlSlider";
import styles from "./ControlBlinds.module.css";

const ControlBlinds: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const [blinds, setBlinds] = useState<any[]>([]);
    const {isConnecting, presentResult, alarmState} = useUX();

    useEffect(() => {
        const subscription = IWBlinds.blinds$.subscribe(blinds => {
            setBlinds(Object.values(blinds));
        });

        setLoading(true);

        IWBlinds.requestIfNeeded().finally(() => {
            setLoading(false);
        });

        return () => {
            subscription.unsubscribe();
        }
    }, []);

    async function requestAllBlindsPosition(position: number) {
        await presentResult(async () => {
            await IWBlinds.requestAllBlindsPosition(position);
        }, `Setting all blinds to ${position}%`);
    }

    return (
        <IonPage>
            <IonHeader translucent={isPlatform('capacitor')} collapse="fade">
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/control"/>
                    </IonButtons>
                    <IonTitle>All Blinds</IonTitle>
                    <IonButtons slot="primary">
                        <IonButton onClick={() => requestAllBlindsPosition(0)}>
                            <IonIcon slot="icon-only" src="/assets/icon/blinds-up.svg"/>
                        </IonButton>
                        <IonButton onClick={() => requestAllBlindsPosition(100)}>
                            <IonIcon slot="icon-only" src="/assets/icon/blinds-down.svg"/>
                        </IonButton>
                    </IonButtons>
                    {!!(isConnecting || loading) && <IonProgressBar type="indeterminate"/>}
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen={true}>
                {alarmState === 'ARMED' && (
                    <IonItem color="danger" routerLink="/access/alarm" button detail={true}>
                        <IonIcon slot="start" icon={warningOutline}/>
                        <IonLabel>
                            <h2>The alarm is set</h2>
                            <p>Unset it before operating blinds.</p>
                        </IonLabel>
                    </IonItem>)}
                <IonList>
                    {blinds.map((blind: any) => (
                        <IonItem className={styles.item} key={blind.mac}>
                            <IonLabel position="stacked">{blind.name || blind.mac}</IonLabel>
                            <BlindControlSlider key={blind.mac} blind={blind}/>
                        </IonItem>
                    ))}
                </IonList>
            </IonContent>
        </IonPage>
    );
};

export default ControlBlinds;
import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonPage,
    IonTitle,
    IonToolbar,
    IonProgressBar, IonItem, IonLabel, IonAccordion, IonAccordionGroup
} from '@ionic/react';
import {isPlatform} from '@ionic/react';
import React, {useEffect, useState} from "react";
import BlindControlSlider from "../components/BlindControlSlider";
import useUX from "../ux/UX";
import IWBlinds from "../client/IWBlinds";
import {warningOutline, toggleOutline} from "ionicons/icons";
import styles from "./Control.module.css";

const Control: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const [blinds, setBlinds] = useState<{ [mac: string]: any }>({});
    const {isConnecting, alarmState} = useUX();
    const mode = isPlatform("ios") ? "ios" : "md";
    const alarmIsArmed = alarmState === 'ARMED';

    useEffect(() => {
        setLoading(true);

        const subscriptions = [
            IWBlinds.blinds$.subscribe(blinds => setBlinds(blinds))
        ];

        Promise.all([
            IWBlinds.requestIfNeeded()
        ]).finally(() => {
            setLoading(false);
        });

        return () => subscriptions.forEach(subscription => subscription.unsubscribe());
    }, []);

    function indexToLetter(index: number) {
        return String.fromCharCode(index + 'A'.charCodeAt(0));
    }

    return (
        <IonPage>
            <IonHeader translucent={isPlatform('capacitor')} collapse="fade">
                <IonToolbar>
                    <IonTitle>Building Control</IonTitle>
                    <IonButtons slot="primary">
                        <IonButton routerLink="/control/hvac">
                            <IonIcon slot="icon-only" src="/assets/icon/HVAC.svg"/>
                        </IonButton>
                        <IonButton routerLink="/control/blinds">
                            <IonIcon slot="icon-only" src="/assets/icon/blinds.svg"/>
                        </IonButton>
                        <IonButton routerLink="/control/relays">
                            <IonIcon slot="icon-only" icon={toggleOutline} />
                        </IonButton>
                    </IonButtons>
                    {(loading || isConnecting) && <IonProgressBar type="indeterminate"/>}
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen={true}>
                {alarmIsArmed && (
                    <IonItem color="danger" routerLink="/access/alarm" button detail={true}>
                        <IonIcon slot="start" icon={warningOutline}/>
                        <IonLabel>
                            <h2>The alarm is set</h2>
                            <p>Devices near sensors are disabled.</p>
                        </IonLabel>
                    </IonItem>)}
                <IonAccordionGroup expand="inset" mode="md">
                    <IonAccordion className={styles.room}>
                        <IonItem slot="header" color="light">
                            <IonLabel>Reception</IonLabel>
                        </IonItem>
                        <div slot="content">
                            {Object.values(blinds).filter(b => b.roomId === '00.86').map((blind: any, index) => (
                                <IonItem lines="full" className={styles.item} key={blind.mac} mode={mode}>
                                    <div className={styles.letter} slot="start">{indexToLetter(index)}</div>
                                    <BlindControlSlider blind={blind}/>
                                </IonItem>
                            ))}
                        </div>
                    </IonAccordion>
                    <IonAccordion className={styles.room}>
                        <IonItem slot="header" color="light">
                            <IonLabel>Conference Room</IonLabel>
                        </IonItem>
                        <div slot="content">
                            {Object.values(blinds)
                                .filter(b => ['00.83', '00.136'].includes(b.roomId))
                                .filter(b => !b.name.includes('Blackout'))
                                .map((blind: any, index) => {
                                    const blackoutBlind = Object.values(blinds).find(b => b.name === blind.name.replace('Mesh', 'Blackout'));

                                    return (<IonItem lines="full" className={styles.item} key={blind.mac} mode={mode}>
                                        <div className={styles.letter} slot="start">{indexToLetter(index)}</div>
                                        <BlindControlSlider blind={blind} blackoutBlind={blackoutBlind}
                                                            disabled={alarmIsArmed}/>
                                    </IonItem>);
                                })}
                        </div>
                    </IonAccordion>
                    <IonAccordion className={styles.room}>
                        <IonItem slot="header" color="light">
                            <IonLabel>WOLF Office</IonLabel>
                        </IonItem>
                        <div slot="content">
                            {Object.values(blinds).filter(b => b.roomId === '00.76').map((blind: any, index) => (
                                <IonItem lines="full" className={styles.item} key={blind.mac} mode={mode}>
                                    <div className={styles.letter} slot="start">{indexToLetter(index)}</div>
                                    <BlindControlSlider blind={blind}/>
                                </IonItem>
                            ))}
                        </div>
                    </IonAccordion>
                    <IonAccordion className={styles.room}>
                        <IonItem slot="header" color="light">
                            <IonLabel>Upstairs Office</IonLabel>
                        </IonItem>
                        <div slot="content">
                            {Object.values(blinds).filter(b => b.roomId === '00.98').map((blind: any, index) => (
                                <IonItem lines="full" className={styles.item} key={blind.mac} mode={mode}>
                                    <div className={styles.letter} slot="start">{indexToLetter(index)}</div>
                                    <BlindControlSlider blind={blind}/>
                                </IonItem>
                            ))}
                        </div>
                    </IonAccordion>
                </IonAccordionGroup>
            </IonContent>
        </IonPage>
    );
};

export default Control;

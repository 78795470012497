import React, {useState} from "react";
import {IonButton, IonIcon, IonRange} from "@ionic/react";
import IWBlinds from "../client/IWBlinds";
import useUX from "../ux/UX";
import styles from "./BlindControlSlider.module.css";
import classnames from 'classnames';
import {RangeValue} from "@ionic/core";

interface Props {
    blind: any;
    blackoutBlind?: any;
    className?: string;
    disabled?: boolean;
}

const BlindControlSlider: React.FC<Props> = ({blind, blackoutBlind, className, disabled}) => {
    const [lockedValue, setLockedValue] = useState<RangeValue | null>(null);
    const {presentResult} = useUX();

    async function requestBlindPosition(position: RangeValue) {
        setLockedValue(position);

        await presentResult(async () => {
                await IWBlinds.requestBlindPosition(blind.mac, typeof position === 'number' ? position : position.upper);

                if (blackoutBlind) {
                    await IWBlinds.requestBlindPosition(blackoutBlind.mac, typeof position === 'number' ? position : position.lower);
                }
            }, typeof position === 'number'
                ? `Setting blind position to ${position}%`
                : `Setting blind positions to ${position.lower}%, ${position.upper}%`
        );

        setLockedValue(null);
    }

    return blind && (
        <IonRange className={classnames(styles.slider, className)}
                  ticks={true} snaps={true} min={0} max={100} step={10} pin={true}
                  dualKnobs={!!blackoutBlind} disabled={disabled || undefined}
                  value={lockedValue ||
                      (!blackoutBlind
                          ? blind.data?.currentPosition
                          : {
                              lower: blind.data?.currentPosition,
                              upper: blackoutBlind.data?.currentPosition,
                          })}
                  onIonKnobMoveEnd={({detail}) => requestBlindPosition(detail.value)}
                  aria-label={`${blind.name} Position`}>
            <IonButton slot="start" fill="clear" onClick={() => requestBlindPosition(0)}>
                <IonIcon slot="icon-only" src="/assets/icon/blinds-up.svg"/>
            </IonButton>
            <IonButton slot="end" fill="clear" onClick={() => requestBlindPosition(100)}>
                <IonIcon slot="icon-only" src="/assets/icon/blinds-down.svg"/>
            </IonButton>
        </IonRange>
    );
};

export default BlindControlSlider;

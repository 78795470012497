import {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonItem, IonProgressBar
} from '@ionic/react';
import {IonButton, IonIcon, IonNote, IonCard, IonGrid, IonRow, IonCol, IonLabel} from '@ionic/react';
import {useIonViewWillEnter, useIonViewDidLeave} from '@ionic/react';
import {useIonToast} from '@ionic/react';
import {isPlatform} from '@ionic/react';
import {arrowUp, arrowDown, warningOutline, alertCircle} from 'ionicons/icons';
import styles from './AccessWorkshopRollerDoor.module.css';
import IWBrokerClient from "../client/IWBrokerClient";
import React, {memo, useState} from "react";
import useUX from "../ux/UX";

const AccessWorkshopRollerDoor: React.FC = () => {
    const [presentToast] = useIonToast();
    const {alarmState, isConnecting} = useUX();

    /** Actions the roller shutter door - Tasmota 'PulseTime 10' will restrict operations to 1 second unless repeated
     *
     * @param State for the roller shutter ['open', 'close', 'stop']
     */
    async function workshopDoorRollerState(state: String) {
        try {
            await IWBrokerClient.volatileRequest('00/workshop/door/roller', state);
        } catch (e: any) {
            await presentToast({
                message: e.message,
                duration: 2500,
                color: 'danger',
                icon: alertCircle
            });
        }
    }

    async function workshopDoorRollerStateUntilReleased(state: String) {
        let timeout: any = null;
        let cancelled = false;

        const requestStateRepeatedly = async () => {
            await workshopDoorRollerState(state);

            if (!cancelled) {
                timeout = window.setTimeout(requestStateRepeatedly, 500);
            }
        }

        document.addEventListener(
            "pointerup",
            async () => {
                window.clearTimeout(timeout);
                cancelled = true;
                await workshopDoorRollerState('stop');
            },
            {once: true}
        );

        await requestStateRepeatedly();
    }

    const workshopDoorRollerOpen = async () => workshopDoorRollerStateUntilReleased('open');
    const workshopDoorRollerClose = async () => workshopDoorRollerStateUntilReleased('close');

    const [cameraMjpegSrc, setCameraMjpegSrc] = useState<string | null>(null);

    useIonViewWillEnter(() => {
        console.log('Starting Webcam Stream');

        setCameraMjpegSrc(IWBrokerClient.getCameraMjpegURL('10.0.4.123'));
    });

    useIonViewDidLeave(() => {
        console.log('Stopping Webcam Stream')

        setCameraMjpegSrc(null);
        window.stop();
    });

    return (
        <IonPage>
            <IonHeader translucent={isPlatform('capacitor')}>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="../"/>
                    </IonButtons>
                    <IonTitle>Workshop Roller Door</IonTitle>
                    {!!isConnecting && <IonProgressBar type="indeterminate"/>}
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen={true}>
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large">Workshop Roller Door</IonTitle>
                    </IonToolbar>
                </IonHeader>
                {alarmState === 'ARMED' && (
                    <IonItem color="danger" routerLink="/access/alarm" button detail={true}>
                        <IonIcon slot="start" icon={warningOutline}/>
                        <IonLabel>
                            <h2>The alarm is set</h2>
                            <p>Unset it before operating roller.</p>
                        </IonLabel>
                    </IonItem>)}
                <IonCard>
                    <div className={styles.cameraContainer}>
                        { cameraMjpegSrc && <img alt="Camera View" className={styles.camera} src={cameraMjpegSrc}/> }
                    </div>
                </IonCard>
                <IonCard>
                    <IonGrid>
                        <IonRow style={{touchAction: 'none'}}>
                            <IonCol>
                                <IonButton expand="block" onPointerDown={workshopDoorRollerOpen}>
                                    <IonIcon slot="icon-only" icon={arrowUp}></IonIcon>
                                </IonButton>
                            </IonCol>
                            <IonCol>
                                <IonButton expand="block" onPointerDown={workshopDoorRollerClose}>
                                    <IonIcon slot="icon-only" icon={arrowDown}></IonIcon>
                                </IonButton>
                            </IonCol>
                        </IonRow>
                        <IonRow style={{display: 'flex'}}>
                            <IonNote style={{margin: 'auto'}}>Hold to Control</IonNote>
                        </IonRow>
                    </IonGrid>
                </IonCard>
            </IonContent>
        </IonPage>
    );
};

export default memo(AccessWorkshopRollerDoor);

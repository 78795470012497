import {
    IonHeader,
    IonPage,
    IonProgressBar,
    IonTitle,
    IonToolbar,
    IonContent,
    IonCard,
    IonCardContent,
    IonList,
    IonItem,
    IonLabel,
    IonThumbnail,
    IonCardHeader,
    IonCardTitle, IonChip, IonIcon,
    useIonActionSheet,
    useIonAlert, IonAvatar, IonButton, IonButtons
} from '@ionic/react';
import {isPlatform} from '@ionic/react';
import styles from './EV.module.css';
import React, {memo, useEffect, useState} from "react";
import useUX from "../ux/UX";
import IWEV from "../client/IWEV";
import {play, carSportOutline} from "ionicons/icons";
import IWBrokerClient from "../client/IWBrokerClient";
import UserName from "../components/UserName";

function ChargePointImage(props: any) {
    const {connector, onClick} = props;

    switch (connector.status) {
        case 'Charging':
            return (<IonThumbnail onClick={onClick} class={styles.listImage} slot="start">
                <img alt="Project EV Charger" src={`/assets/ev/project-ev-charging-${connector.id}.png`}/>
            </IonThumbnail>)

        case 'Preparing':
        case 'Finishing':
        case 'SuspendedEV':
            return (<IonThumbnail onClick={onClick} class={styles.listImage} slot="start">
                <img alt="Project EV Charger" src={`/assets/ev/project-ev-car-${connector.id}.png`}/>
            </IonThumbnail>)

        default:
            return (<IonThumbnail onClick={onClick} class={styles.listImage} slot="start">
                <img alt="Project EV Charger" src={`/assets/ev/project-ev-connector-${connector.id}.png`}/>
            </IonThumbnail>)
    }
}

function ChargePointDetailLine({connector}: any) {
    const items = [];

    if (connector.transaction) {
        const {timestampStart, timestampStop, meterStart, meterStop} = connector.transaction;

        let meterLatest =
            meterStop
            || connector.meterValues?.['Energy.Active.Import.Register']?.value
            || meterStart;

        let timestampLatest =
            timestampStop
            || (connector.meterValues
                && Object.values(connector.meterValues).reduce((v, a: any) => a.timestamp || v));

        if (timestampStop && connector.status !== 'Finishing') {
            return null;
        }

        const power = (connector.meterValues
            && (Object.entries(connector.meterValues) as any[])
                .filter(([k]) => k.startsWith('Power.Active.Import.'))
                .reduce((a, [_, v]) => Number(v.value) + a, 0));

        if (timestampStart && timestampLatest) {
            const dateStart = new Date(timestampStart), dateLatest = new Date(timestampLatest);
            const totalMinutes = (dateLatest.getTime() - dateStart.getTime()) / 1000 / 60;
            const minutes = Math.floor(totalMinutes % 60), hours = Math.floor(totalMinutes / 60);
            items.push(<span>{hours}<small>h&nbsp;</small>{minutes}<small>m</small></span>);
        }

        if (power && connector.status === "Charging") {
            items.push(<span>{(power / 1000).toPrecision(2)}<small>kW</small></span>);
        }

        if (meterStart && meterLatest
            && connector.status !== 'Unavailable'
            && connector.status !== 'Preparing') {
            const wh = meterLatest - meterStart;
            items.push(<span><span
                className={styles.detailSmallMiddle}>+</span>{Math.round(wh / 1000)}<small>kWh</small></span>);
        }
    }

    const interleave = (arr: any, thing: any) => [].concat(...arr.map((n: any) => [n, thing])).slice(0, -1);
    return items.length ? <p>{React.Children.toArray(interleave(items, <span> · </span>))}</p> : null;
}

function ChargePointUserLine({connector}: any) {
    if (connector.transaction) {
        const {userId} = connector.transaction;

        if (userId && connector.status !== "Unavailable") {
            return (<h3>
                <IonAvatar className={styles.inlineAvatar}>
                    <img alt="avatar" src={IWBrokerClient.getAvatarURL(userId)}/>
                </IonAvatar>
                <UserName userId={userId} />
            </h3>)
        }
    }

    return null;
}

const EV: React.FC = () => {
    const [presentActionSheet] = useIonActionSheet();
    const [presentAlert] = useIonAlert();
    const [activeRequests, setActiveRequests] = useState(0);
    const [chargers, setChargers] = useState<any[]>([]);
    const {isConnecting, presentResult} = useUX();

    useEffect(() => {
        const subscription = IWEV.chargers$.subscribe(chargers => {
            setChargers(Object.values(chargers).sort((a: any, b: any) => a.name.localeCompare(b.name)));
        });

        setActiveRequests(r => r + 1);

        IWEV.requestChargersIfNeeded().finally(() => {
            setActiveRequests(r => r - 1);
        });

        return () => {
            subscription.unsubscribe();
        }
    }, []);

    async function start(chargeStationId: string, connectorId: number) {
        setActiveRequests(r => r + 1);

        await presentResult(async () => {
            await IWEV.sendRequest(chargeStationId, {
                action: 'RemoteStartTransaction',
                connectorId,
                ocppVersion: 'v1.6-json',
                idTag: chargeStationId
            });
        }, 'Starting Charging Session');

        setActiveRequests(r => r - 1);
    }

    async function stop(chargeStationId: string, transactionId: number) {
        setActiveRequests(r => r + 1);

        await presentResult(async () => {
            await IWEV.sendRequest(chargeStationId, {
                action: 'RemoteStopTransaction',
                ocppVersion: 'v1.6-json',
                transactionId
            });
        }, 'Stopping Transaction #' + transactionId);

        setActiveRequests(r => r - 1);
    }

    async function showStatus(chargeStation: any) {
        await presentAlert({
            header: 'Charge Station Status',
            inputs: [
                {
                    type: 'textarea',
                    value: JSON.stringify(chargeStation, null, 2),
                    cssClass: styles.alertLongText
                }
            ],
            buttons: ['OK'],
        });
    }

    async function getConfiguration(chargeStationId: string) {
        try {
            setActiveRequests(r => r + 1);

            const data = await IWEV.sendRequest(chargeStationId, {
                action: 'GetConfiguration',
                ocppVersion: 'v1.6-json'
            });

            await presentAlert({
                header: 'Charge Station Configuration',
                inputs: [
                    {
                        type: 'textarea',
                        value: JSON.stringify(data, null, 2),
                        cssClass: styles.alertLongText
                    }
                ],
                buttons: ['OK'],
            });
        } finally {
            setActiveRequests(r => r - 1);
        }
    }

    async function reset(chargeStationId: string) {
        setActiveRequests(r => r + 1);

        await presentResult(async () => {
            await IWEV.sendRequest(chargeStationId, {
                action: 'Reset',
                ocppVersion: 'v1.6-json',
                type: 'Hard'
            });
        }, 'Rebooting Charging Session');

        setActiveRequests(r => r - 1);
    }

    async function presentChargerActionSheet(charger: any, connector: any) {
        await presentActionSheet({
            header: charger.name + "-AB"[connector.id],
            subHeader: 'Diagnostic Menu ',
            buttons: [
                {
                    text: 'Start Charging',
                    handler: () => start(charger.id, connector.id)
                },
                {
                    text: ['Stop Transaction', connector.transaction?.id].join(' '),
                    handler: () => {
                        if (!connector.transaction) {
                            return presentAlert('No Active Transaction');
                        }
                        stop(charger.id, connector.transaction.id);
                    }
                },
                {
                    text: 'Get Configuration',
                    handler: () => getConfiguration(charger.id)
                },
                {
                    text: 'Show Status',
                    handler: () => showStatus(charger)
                },
                {
                    text: 'Reboot',
                    role: 'destructive',
                    handler: () => reset(charger.id)
                },
                {
                    text: 'Cancel',
                    role: 'cancel',
                }
            ]
        })
    }

    function ChargePointAction(charger: any, connector: any) {
        switch (connector.status) {
            case 'Preparing':
                switch (connector.info) {
                    case 'ChargeWait':
                        return <IonChip color="warning" className={styles.actionChip}>
                            <IonIcon icon={carSportOutline} color="white"/>
                            <IonLabel>Waiting</IonLabel>
                        </IonChip>

                    default:
                        return (<IonChip onClick={() => start(charger.id, connector.id)} disabled={!!activeRequests}
                                         className={`${styles.actionChip} ${styles.button}`}>
                            <IonIcon icon={play} color="white"/>
                            <IonLabel>Start</IonLabel>
                        </IonChip>)
                }

            case 'Available':
                return (<IonChip color="primary" className={styles.actionChip}>{connector.status}</IonChip>)

            case 'Charging':
                return (<IonChip className={`${styles.actionChip} ${styles.charging}`}>{connector.status}</IonChip>)

            case 'SuspendedEV':
                return (<IonChip color="warning" className={styles.actionChip}>
                    <IonIcon icon={carSportOutline} color="white"/>
                    <IonLabel>Paused</IonLabel>
                </IonChip>)

            case 'Unavailable':
                return <IonChip color="medium" className={styles.actionChip}>{connector.status}</IonChip>

            case 'Finishing':
                return (<IonChip className={`${styles.actionChip} ${styles.complete}`}>
                    <IonLabel>Finished</IonLabel>
                </IonChip>)

            default:
                return <IonChip className={styles.actionChip}>{connector.status}</IonChip>
        }
    }

    function ChargePoint(charger: any) {
        return (
            <IonCard>
                <IonCardHeader>
                    <IonCardTitle>{charger.name || charger.id}</IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                    <IonList className={styles.list}>
                        {Object.values(charger.connectors).map((connector: any, idx) => (
                            <IonItem key={connector.id} className={styles.chargePointListItem}
                                     lines={idx ? 'none' : 'inset'}>
                                <ChargePointImage connector={connector}
                                                  onClick={() => presentChargerActionSheet(charger, connector)}/>
                                <IonLabel>
                                    <h2>Connector {"-AB"[connector.id]}</h2>
                                    <ChargePointDetailLine charger={charger} connector={connector}/>
                                    <ChargePointUserLine charger={charger} connector={connector}/>
                                </IonLabel>
                                {ChargePointAction(charger, connector)}
                            </IonItem>
                        ))}
                    </IonList>
                </IonCardContent>
            </IonCard>
        )
    }

    return (
        <IonPage>
            <IonHeader translucent={isPlatform('capacitor')} collapse="fade">
                <IonToolbar>
                    <IonTitle>EV Charging</IonTitle>
                    <IonButtons slot="primary">
                        <IonButton routerLink="/ev/camera">
                            <IonIcon slot="icon-only" src="/assets/icon/ai-vision.svg"/>
                        </IonButton>
                    </IonButtons>
                    {!!(isConnecting || activeRequests) && <IonProgressBar type="indeterminate"/>}
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen={true}>
                {chargers.map(charger =>
                    <React.Fragment key={charger.id}>
                        {ChargePoint(charger)}
                    </React.Fragment>)}
            </IonContent>
        </IonPage>
    );
};

export default memo(EV);
